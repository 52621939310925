import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Search } from "react-feather";
import { useRecoilValue } from "recoil";
import toast from "react-hot-toast";
import {
  AnimatePresence,
  motion,
  Reorder,
  useDragControls,
} from "framer-motion";
import {
  AccountDefault,
  UserSettingsDefault,
} from "../constants/TypesDefaults";
import { PAGE_TITLE_BUTTON_TYPE, STORAGE_KEYS } from "../constants/Enums";
import { AccountType, UserSettingsType } from "../constants/Types";
import { supportedChainsState } from "../state/SupportedChainsState";
import PageTitle from "../components/PageTitle";
import {
  getExplorerAddressUrl,
  getItemFromStorage,
  getUserSettingsData,
  setUserSettingsData,
  getActiveUserDevice,
  getCoinBalance,
  fixPrecisionToDigits,
  isAddressSame,
} from "../utils/helper";
import SwaAddressCard from "../components/SwaAddressCard";
import { Button } from "../components/ui/button";
import useWallet from "../lib/store/hooks/useWallet";
import dragIcon from "../assets/dragIcon.svg";

const SelectAnAddress = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [explorerUrl, setExplorerUrl] = useState<string>("");
  const [addressHovered, setAddressHovered] = useState<string | null>(null);
  const [activeWallet, setActiveWallet] =
    useState<UserSettingsType>(UserSettingsDefault);
  const [allWallets, setAllWallets] = useState<UserSettingsType[]>([
    UserSettingsDefault,
  ]);
  const [listOfAccounts, setListOfAccounts] = useState<AccountType[]>([
    AccountDefault,
  ]);
  const [listOfEoa, setListOfEoa] = useState<string[]>([]);

  const [activeModal, setActiveModal] = useState<string | null>(null);

  const chainIDFromStorage = getItemFromStorage(STORAGE_KEYS.NETWORK);
  const smartAccountAddressInStorage = getItemFromStorage(
    STORAGE_KEYS.SMART_ACCOUNT,
  );
  const eoaAddressInStorage = getItemFromStorage(STORAGE_KEYS.SIGNER);

  const {
    eoaAddress,
    smartAccountAddress,
    userSettings,
    getProvider,
    init,
    addNewAccount,
    updateUserSettings,
    chainData,
  } = useWallet();
  const provider = getProvider();
  const dragControls = useDragControls();

  const chains = useRecoilValue(supportedChainsState);

  const location = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [dragListenerBoolean, setDragListenerBoolean] = useState(false);
  const [dragCursor, setDragCursor] = useState<string>("grab");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const buttonVariants = {
    selected: { flex: 1, opacity: 1 },
    notSelected: { flex: 0.5, opacity: 0.5 },
  };

  const setAllWalletsFromUserSettings = async () => {
    const allWalletsInStorage: UserSettingsType[] = await getUserSettingsData();

    setAllWallets(allWalletsInStorage);

    const activeWalletInStorage = await getActiveUserDevice(
      eoaAddress || eoaAddressInStorage,
    );

    setActiveWallet(activeWalletInStorage);
  };

  useEffect(() => {
    setExplorerUrl(getExplorerAddressUrl(chainIDFromStorage, chains));

    setAllWalletsFromUserSettings();
  }, [
    smartAccountAddress,
    smartAccountAddressInStorage,
    chains,
    listOfAccounts,
    allWallets.length,
    userSettings?.isEoaSelected,
  ]);

  const updateUserSetting = async (sortedAccounts: AccountType[]) => {
    const usersSettings: UserSettingsType[] = allWallets;

    const userIndex = usersSettings.findIndex((user: UserSettingsType) =>
      isAddressSame(user.address, eoaAddress || ""),
    );

    if (userIndex !== -1) {
      usersSettings[userIndex].accounts = sortedAccounts;
    }

    await setUserSettingsData(usersSettings);
  };

  const sortAndSetAccounts = (accounts: AccountType[]) => {
    const sortedAccounts = accounts.sort((a, b) => {
      if (a.position === undefined) return 1;

      if (b.position === undefined) return -1;

      return a.position - b.position;
    });

    setListOfAccounts(sortedAccounts);
  };

  const setEoaAccounts = (address: string) => {
    setListOfEoa([address]);
  };

  useEffect(() => {
    sortAndSetAccounts(activeWallet.accounts);
    setEoaAccounts(activeWallet.address);

    const getBalances = async () => {
      const listOfBalances = [];

      // eslint-disable-next-line security-node/detect-unhandled-async-errors
      for (let i = 0; i < activeWallet?.accounts?.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        let balance = await getCoinBalance(
          activeWallet?.accounts[i].address,
          provider,
        );

        balance = `${fixPrecisionToDigits(Number(balance), 5)}`;
        listOfBalances.push(balance);
      }
    };

    if (provider) getBalances();
  }, [
    activeWallet.address,
    activeWallet.accounts.length,
    allWallets.length,
    listOfAccounts.length,
  ]);

  const selectAccount = (idx: number) => {
    init({
      chainId: chainIDFromStorage,
      walletName: activeWallet.walletName,
      smartAccountIndex: idx,
    });
  };

  const addAccount = async () => {
    await addNewAccount();
    await setAllWalletsFromUserSettings();
  };

  const switchAccountsType = async (isEoa: boolean) => {
    const usersSettings: UserSettingsType[] = [...allWallets];
    const userIndex = usersSettings.findIndex((user: UserSettingsType) =>
      isAddressSame(user.address, eoaAddress || ""),
    );

    if (userIndex !== -1) {
      usersSettings[userIndex] = {
        ...usersSettings[userIndex],
        isEoaSelected: isEoa,
      };
    }

    await setUserSettingsData(usersSettings);
    updateUserSettings(usersSettings[userIndex]);
  };

  const handleReorder = (newOrder: any) => {
    setListOfAccounts(newOrder);
  };

  const updatePositions = async (newOrder: any) => {
    const usersSettings: UserSettingsType[] = allWallets;

    const userIndex = usersSettings.findIndex((user: UserSettingsType) =>
      isAddressSame(user.address, eoaAddress || ""),
    );

    if (userIndex !== -1) {
      const allAccounts = usersSettings[userIndex].accounts;

      const updatedAccounts = allAccounts.map((account: any) => {
        const newPosition = newOrder.findIndex((newAccount: any) =>
          isAddressSame(newAccount.address, account.address),
        );

        if (newPosition !== -1) {
          return { ...account, position: newPosition + 1 };
        }

        return account;
      });

      await updateUserSetting(updatedAccounts);
    }
  };

  const getSelectedAccount = (address: string) => {
    if (location.state?.from === "/setting/edit-account") return null;

    const usersSettings: UserSettingsType[] = allWallets;

    const userIndex = usersSettings.findIndex((user: UserSettingsType) =>
      isAddressSame(user.address, eoaAddress || ""),
    );

    if (userIndex !== -1) {
      const allAccounts = usersSettings[userIndex].accounts;

      const accountIndex = allAccounts.findIndex(
        (account: { address: string }) =>
          isAddressSame(account.address, address),
      );

      selectAccount(accountIndex);
    }

    return -1;
  };

  const showSmartAccountWallets = () => (
    <>
      <div className="relative">
        <div className="absolute h-6 w-full top-0 left-0 bg-gradient-to-b from-primary-bg to-transparent z-30"></div>

        <Reorder.Group
          axis="y"
          onReorder={(newOrder) => {
            handleReorder(newOrder);
            updatePositions(newOrder);
          }}
          values={listOfAccounts}
          className="h-[420px] py-6 overflow-y-auto"
        >
          {listOfAccounts.map((account, index) => (
            <Reorder.Item
              key={account.address}
              value={account}
              dragListener={dragListenerBoolean}
              className={`bg-card-bg rounded-xl cursor-pointer ${
                index === listOfAccounts.length - 1 ? "mb-0" : "mb-2"
              }`}
            >
              <motion.div
                onHoverStart={() => setAddressHovered(account.address)}
                onHoverEnd={() => setAddressHovered(null)}
                className="flex items-center p-1 px-2 w-full"
              >
                <AnimatePresence>
                  {addressHovered !== null &&
                    isAddressSame(addressHovered, account.address) && (
                      <motion.img
                        onHoverStart={() => setDragListenerBoolean(true)}
                        onHoverEnd={() => setDragListenerBoolean(false)}
                        src={dragIcon}
                        onPointerDown={(event) => {
                          setDragCursor("grabbing");
                          dragControls.start(event);
                        }}
                        onPointerUp={() => {
                          setDragCursor("grab");
                        }}
                        className={`h-6 w-4 cursor-${dragCursor}`}
                        draggable="false"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: 16 }}
                        exit={{ opacity: 0, width: 0 }}
                        transition={{ duration: 0.2 }}
                      />
                    )}
                </AnimatePresence>

                <div
                  className="w-full"
                  onClick={() => getSelectedAccount(account.address)}
                >
                  <SwaAddressCard
                    name={account.name}
                    address={account.address}
                    explorerUrl={`${explorerUrl}${account.address}`}
                    isActive={isAddressSame(activeModal || "", account.address)}
                    setActiveModal={setActiveModal}
                    modalRef={modalRef}
                    from={location.state?.from}
                    setButtonDisabled={setIsButtonDisabled}
                  />
                </div>
              </motion.div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
        <div className="absolute h-6 w-full bottom-[-2px] left-0 bg-gradient-to-b from-transparent  to-primary-bg z-30"></div>
      </div>
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex items-center justify-center w-full z-30">
        <Button
          onClick={async () => {
            addAccount();
          }}
          variant={"tsxButton"}
          size={"lg"}
        >
          Add Address
        </Button>
      </div>
    </>
  );

  const showEoaAccountWallets = () => (
    <>
      <div className="relative">
        <div className="absolute h-6 w-full top-0 left-0 bg-gradient-to-b from-primary-bg to-transparent z-30"></div>

        <Reorder.Group
          axis="y"
          onReorder={(newOrder) => {
            handleReorder(newOrder);
            updatePositions(newOrder);
          }}
          values={listOfEoa}
          className="h-[420px] py-6 overflow-y-auto"
        >
          {listOfEoa.map((account, index) => (
            <Reorder.Item
              key={index}
              value={account}
              dragListener={dragListenerBoolean}
              className={`bg-card-bg rounded-xl cursor-pointer ${
                index === listOfEoa.length - 1 ? "mb-0" : "mb-2"
              }`}
            >
              <motion.div
                onHoverStart={() => setAddressHovered(account)}
                onHoverEnd={() => setAddressHovered(null)}
                className="flex items-center p-1 px-2 w-full"
              >
                <AnimatePresence>
                  {addressHovered !== null &&
                    isAddressSame(addressHovered, account) && (
                      <motion.img
                        onHoverStart={() => setDragListenerBoolean(true)}
                        onHoverEnd={() => setDragListenerBoolean(false)}
                        src={dragIcon}
                        onPointerDown={(event) => {
                          setDragCursor("grabbing");
                          dragControls.start(event);
                        }}
                        onPointerUp={() => {
                          setDragCursor("grab");
                        }}
                        className={`h-6 w-4 cursor-${dragCursor}`}
                        draggable="false"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: 16 }}
                        exit={{ opacity: 0, width: 0 }}
                        transition={{ duration: 0.3 }}
                      />
                    )}
                </AnimatePresence>

                <div className="w-full">
                  <SwaAddressCard
                    name={""}
                    address={account}
                    explorerUrl={`${explorerUrl}${account}`}
                    isActive={isAddressSame(activeModal || "", account)}
                    setActiveModal={setActiveModal}
                    modalRef={modalRef}
                    from={location.state?.from}
                    isEoa={true}
                    setButtonDisabled={setIsButtonDisabled}
                  />
                </div>
              </motion.div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
        <div className="absolute h-6 w-full bottom-[-2px] left-0 bg-gradient-to-b from-transparent  to-primary-bg z-30"></div>
      </div>
      {/* Uncomment this only if we want to create multiple EOA. Do not remove */}
      {/* <div className="absolute bottom-4 left-4 w-full">
        <Button
          onClick={async () => {
            addAccount();
          }}
          variant={"tsxButton"}
          size={"lg"}
        >
          Add Address
        </Button>
      </div> */}
    </>
  );

  return (
    <div className="h-full select-none">
      <PageTitle
        title={
          location.state?.from === "/setting/edit-account"
            ? "Smart Addresses"
            : "Wallets"
        }
        leftButtonIcon={
          location.state?.from === "/setting/edit-account"
            ? PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT
            : PAGE_TITLE_BUTTON_TYPE.NONE
        }
        leftDestinationRoute={location.state?.from || ""}
        rightButtonIcon={
          location.state?.from === "/setting/edit-account"
            ? PAGE_TITLE_BUTTON_TYPE.NONE
            : PAGE_TITLE_BUTTON_TYPE.X
        }
        rightDestinationRoute={location.state?.from || "/dashboard"}
      />

      <div className="">
        <div className="w-full flex gap-2">
          <motion.button
            disabled={isButtonDisabled}
            onClick={() => {
              if (!activeWallet.isEoaSelected) switchAccountsType(true);
            }}
            className={`p-2 rounded bg-card-bg2 whitespace-nowrap `}
            variants={buttonVariants}
            animate={activeWallet.isEoaSelected ? "selected" : "notSelected"}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <p
              className={` flex items-center justify-center duration-300 ${
                !activeWallet.isEoaSelected && "text-sm"
              }`}
            >
              <span
                className={`${
                  isButtonDisabled || !activeWallet.isEoaSelected
                    ? "opacity-50"
                    : ""
                }`}
              >
                General Acc
              </span>
              <motion.span
                initial={{ width: 0, opacity: 0 }}
                animate={{
                  width: activeWallet.isEoaSelected ? "auto" : 0,
                  opacity: activeWallet.isEoaSelected ? 1 : 0,
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                style={{ display: "inline-block", overflow: "hidden" }}
                className={``}
              >
                ounts
              </motion.span>
            </p>
          </motion.button>

          <motion.button
            disabled={isButtonDisabled}
            onClick={() => {
              if (chainData?.isOnlyEoa) {
                toast.error(
                  `${chainData.name} network doesn't support smart accounts.`,
                );
                return;
              }

              if (activeWallet.isEoaSelected) switchAccountsType(false);
            }}
            className={`p-2 rounded bg-card-bg2 whitespace-nowrap `}
            variants={buttonVariants}
            animate={!activeWallet.isEoaSelected ? "selected" : "notSelected"}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <p
              className={` flex items-center justify-center duration-300 ${
                activeWallet.isEoaSelected && "text-sm"
              }`}
            >
              <span
                className={`${
                  isButtonDisabled || activeWallet.isEoaSelected
                    ? "opacity-50"
                    : ""
                }`}
              >
                Smart Acc
              </span>
              <motion.span
                initial={{ width: 0, opacity: 0 }}
                animate={{
                  width: !activeWallet.isEoaSelected ? "auto" : 0,
                  opacity: !activeWallet.isEoaSelected ? 1 : 0,
                }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                style={{ display: "inline-block", overflow: "hidden" }}
                className={``}
              >
                ounts
              </motion.span>
            </p>
          </motion.button>
        </div>

        <div className="flex items-center border border-gray-300 rounded-lg mt-4  p-2 text-sm">
          <Search className="h-5 mx-auto my-auto opacity-60" />
          <input
            type="text"
            placeholder="Search..."
            className="w-full focus:outline-none pl-1 bg-transparent"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        {activeWallet.isEoaSelected
          ? showEoaAccountWallets()
          : showSmartAccountWallets()}
      </div>
    </div>
  );
};

export default SelectAnAddress;
