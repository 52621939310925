import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import homeIcon from "../assets/NavBarIcons/home-icon.svg";
import nftIcon from "../assets/NavBarIcons/nft-icon.svg";
import featuresIcon from "../assets/NavBarIcons/features-icon.svg";
import activityIcon from "../assets/NavBarIcons/activity-icon.svg";
import settingsIcon from "../assets/NavBarIcons/settings-icon.svg";
import TransactDrawer from "./TransactDrawer";
import { getAccentColor } from "../utils/helper";
import useWallet from "../lib/store/hooks/useWallet";

const BottomNavBar = () => {
  const { chainData } = useWallet();
  const [accentColor, setAccentColor] = useState("#000000");

  const fetchTheme = async () => {
    const theme = await getAccentColor(chainData?.chainId);

    setAccentColor(theme);
  };

  useEffect(() => {
    fetchTheme();
  }, [chainData?.chainId]);

  const Menus = [
    {
      name: "Home",
      icon: ` ${homeIcon}`,
      dis: "translate-x-0",
      navigate: "/",
    },
    {
      name: "NFT",
      icon: ` ${nftIcon}`,
      dis: "translate-x-16",
      navigate: "/dashboard/collectibles",
    },
    {
      name: "Transact",
      icon: ` ${featuresIcon}`,
      dis: "translate-x-32",
      navigate: "/dashboard",
    },
    {
      name: "Activity",
      icon: ` ${activityIcon}`,
      dis: "translate-x-48",
      navigate: "/dashboard/activity",
    },
    {
      name: "Settings",
      icon: ` ${settingsIcon}`,
      dis: "translate-x-64",
      navigate: "/dashboard/settings",
    },
  ];
  const [active, setActive] = useState(0);
  const [openTransactDrawer, setOpenTransactDrawer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.includes("settings")) {
      setActive(4);
    }

    if (window.location.pathname.includes("activity")) {
      setActive(3);
    }

    if (window.location.pathname.includes("collectibles")) {
      setActive(1);
    }
  }, []);

  return (
    <div>
      <div
        className={`fixed bottom-0 left-0 flex justify-center w-full backdrop-blur-sm bg-primary-bg bg-opacity-100 ${
          openTransactDrawer ? "z-30 backdrop-blur-sm" : ""
        }`}
        style={{
          boxShadow: `0px 15px 5px #000000`,
        }}
      >
        <div className={`bg-secondary-bg max-h-[4.4rem] px-2 rounded-xl mb-2 `}>
          <ul className="flex relative z-50">
            <span
              style={{ background: accentColor }}
              className={`duration-500 ${Menus[active].dis} border-4 border-primary-bg h-14 w-14 absolute
         -top-5 rounded-3xl -z-10 ml-1 `}
            >
              <span
                className="w-3.5 h-3.5 bg-transparent absolute top-4 -left-[18px] 
          rounded-tr-[9px] "
              ></span>

              <span
                className="w-3.5 h-3.5 bg-transparent absolute top-4 -right-[18px] 
          rounded-tl-[11px] "
              ></span>
            </span>
            {Menus.map((menu, i) => (
              <li
                onClick={() => {
                  if (menu.name !== "Transact") {
                    setOpenTransactDrawer(false);
                    navigate(menu.navigate);
                  } else {
                    setOpenTransactDrawer(!openTransactDrawer);
                  }
                }}
                key={i}
                className="w-16"
              >
                <a
                  className="flex flex-col text-center pt-2"
                  onClick={() => setActive(i)}
                >
                  <span
                    className={` cursor-pointer duration-500 flex items-center justify-center ${
                      i === active && "-mt-3  text-white"
                    }`}
                  >
                    <img className="h-7" src={menu.icon} />
                  </span>
                  <span
                    className={` text-sm ${
                      active === i
                        ? "translate-y-[10px] duration-1000 opacity-100   "
                        : "opacity-0 translate-y-10"
                    } `}
                  >
                    {menu.name}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <TransactDrawer isOpen={openTransactDrawer} />
    </div>
  );
};

export default BottomNavBar;
