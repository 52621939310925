import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { Plus, X } from "react-feather";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  getShortDisplayString,
  validateTransferDetails,
} from "../../utils/helper";
import RemoveModal from "../../components/Modal";
import { transferState } from "../../state/TransferState";
import AssetSelectionDrawer from "../../components/AssetSelectionDrawer";
import { UIDSForAddingAndRemovingAssetsType } from "../../constants/Types";
import {
  ASSET_SELECTION_TASK,
  PAGE_TITLE_BUTTON_TYPE,
} from "../../constants/Enums";
import { Button } from "../../components/ui/button";
import PageTitle from "../../components/PageTitle";
import AssetCard from "../../components/TransactionComponents/AssetCard";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import Web3AvatarComponent from "../../components/Web3AvatarComponent";
import useWallet from "../../lib/store/hooks/useWallet";

const AddTokens = () => {
  const { smartAccountAddress, chainData, eoaAddress, userSettings } =
    useWallet();
  const currentChainData = {
    name: chainData?.name,
    logo: chainData?.chainUri,
  };
  const [transferData, setTransferData] = useRecoilState(transferState);

  const navigate = useNavigate();
  const [isAssetSelectionDrawerOpen, setIsAssetSelectionDrawerOpen] =
    useState<boolean>(false);
  const [isRemoveAddressModalOpen, setIsRemoveAddressModalOpen] =
    useState(false);

  const [isProceedEnable, setIsProceedEnable] = useState(false);

  const [uidToRemoveAddress, setUidToRemoveAddress] = useState<string>("");
  const [uidsForAddingAsset, setUidsForAddingAsset] =
    useState<UIDSForAddingAndRemovingAssetsType>({
      transactionUID: "",
      assetUID: "",
    });
  const [uidsToRemoveAsset, setUidsToRemoveAsset] =
    useState<UIDSForAddingAndRemovingAssetsType>({
      transactionUID: "",
      assetUID: "",
    });

  const [isRemoveTokenModalOpen, setIsRemoveTokenModalOpen] = useState(false);

  const openAssetSelectionDrawer = (
    _transactionUID: string,
    _assetUID: string,
  ) => {
    setUidsForAddingAsset({
      transactionUID: _transactionUID,
      assetUID: _assetUID,
    });
    setIsAssetSelectionDrawerOpen(true);
  };

  const closeAssetSelectionDrawer = (uid: string) => {
    if (uid === uidsForAddingAsset.transactionUID)
      setIsAssetSelectionDrawerOpen(false);
  };

  const openRemoveAssetModal = (transactionUID: string, assetUID: string) => {
    setUidsToRemoveAsset({ transactionUID, assetUID });
    setIsRemoveTokenModalOpen(true);
  };

  const closeRemoveTokenModal = () => {
    setIsRemoveTokenModalOpen(false);
  };

  const handelRemoveToken = () => {
    const { transactionUID, assetUID } = uidsToRemoveAsset;

    const updatedTransferData = transferData.map((transferDetail) => {
      const data = { ...transferDetail };

      if (transferDetail.uid === transactionUID && data.assets) {
        data.assets = data.assets.filter(
          (asset) => asset.assetUID !== assetUID,
        );
      }

      return data;
    });

    setTransferData(updatedTransferData);

    setIsRemoveTokenModalOpen(false);
  };
  const openRemoveAddressModal = (uid: string) => {
    setUidToRemoveAddress(uid);
    setIsRemoveAddressModalOpen(true);
  };

  const closeRemoveAddressModal = () => {
    setIsRemoveAddressModalOpen(false);
  };

  const handleRemoveAddress = () => {
    setTransferData((prevAddresses) =>
      prevAddresses.filter(
        (transferDetails) => transferDetails.uid !== uidToRemoveAddress,
      ),
    );
    setIsRemoveAddressModalOpen(false);

    if (transferData.length === 0) {
      navigate("/dashboard/transaction/add-address");
    }
  };

  const handleProceedButton = () => {
    if (
      validateTransferDetails(transferData) === true &&
      isProceedEnable &&
      transferData.length > 0
    ) {
      navigate("/dashboard/transaction/approve-transactions");
    }
  };

  useEffect(() => {
    validateTransferDetails(transferData);
    setIsProceedEnable(validateTransferDetails(transferData));
  }, [transferData]);

  if (transferData.length === 0) navigate("/dashboard/transaction/add-address");

  const getAddress = () =>
    userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress;

  return (
    <PageTransitionWrapper>
      <div className="h-full w-full relative mx-auto  bg-primary-bg  ">
        <PageTitle
          title="Add Assets"
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          leftDestinationRoute="/dashboard/transaction/add-address"
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          backRouteState={true}
        />

        <div className="text-md flex justify-between items-center bg-card-bg2 border border-black  w-full max-w-[325px]  mx-auto py-2 px-3 rounded-xl text-gray-200">
          <div className="flex gap-2">
            <h1 className=" text-gray-300 font-semibold">From</h1>
            <div className="flex gap-1 justify-center items-center">
              <Web3AvatarComponent
                address={getAddress() || ""}
                className=" w-6 h-6"
              />
              <p className="font-semibold tracking-wide text-sm">
                {getShortDisplayString(getAddress())}
              </p>
              {/* <ChevronDown size={20} /> */}
            </div>
          </div>
          <div className="text-gray-200 my-auto flex justify-center item-center gap-2 ">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <img
                    className="h-9  rounded-full p-1 "
                    src={currentChainData.logo}
                    alt={currentChainData.name}
                  />
                </TooltipTrigger>
                <TooltipContent className=" absolute w-fit text-nowrap right-0 top-10 bg-secondary-bg text-white border-black">
                  <p>{currentChainData.name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <p className=" mt-2 text-sm text-center">Recipients</p>
        <div className="overflow-y-scroll max-h-[450px]  ">
          {transferData.map((transaction) => (
            <div key={transaction.uid}>
              <div className="flex flex-col gap-2 bg-secondary-bg max-w-[325px] border rounded-xl py-2 px-2 border-black mx-auto mt-1 text-white shadow-md text-md">
                <div className=" flex  justify-between   bg-card-bg2 border border-black py-2 px-2 rounded-lg z-30">
                  <div className="flex justify-center item-center gap-2">
                    <Web3AvatarComponent
                      address={transaction.address}
                      className=" w-7 h-7 my-auto"
                    />
                    {transaction.name === "" &&
                    transaction.usersDomainName === "" ? (
                      <p className="font-medium">
                        {getShortDisplayString(transaction.address)}
                      </p>
                    ) : (
                      <div className="flex  gap-3 font-medium">
                        <p>
                          {transaction.name !== ""
                            ? transaction.name
                            : transaction.usersDomainName}
                        </p>
                        <p className="text-sm my-auto">
                          ({getShortDisplayString(transaction.address)})
                        </p>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      openRemoveAddressModal(transaction.uid);
                    }}
                  >
                    <X
                      size={20}
                      className="hover:scale-110 hover:text-red-500"
                    />
                  </button>
                </div>

                {transaction.assets && transaction.assets.length > 0 ? (
                  <>
                    <div>
                      {transaction.assets.map((asset) => (
                        <AssetCard
                          assetCardSelectionTask={
                            ASSET_SELECTION_TASK.TRANSACTION
                          }
                          key={asset.assetUID}
                          asset={asset}
                          transactionUID={transaction.uid}
                          openAssetSelectionDrawer={() =>
                            openAssetSelectionDrawer(
                              transaction.uid,
                              asset.assetUID,
                            )
                          }
                          openRemoveAssetModal={() =>
                            openRemoveAssetModal(
                              transaction.uid,
                              asset.assetUID,
                            )
                          }
                          isProceedEnable={setIsProceedEnable}
                          isReview={false}
                        />
                      ))}
                    </div>

                    {/* Add More assets  */}
                    {userSettings?.isEoaSelected ? (
                      <></>
                    ) : (
                      <div
                        onClick={() => {
                          openAssetSelectionDrawer(transaction.uid, "");
                        }}
                        className=" group-hover:scale-125 text-xs flex place-self-end  gap-1 text-gray-100 hover:cursor-pointer group bg-card-bg2 py-1 px-1 rounded-lg"
                      >
                        <Plus
                          size={13}
                          className="text-gray-100 group-hover:scale-125  "
                        />
                        <p>Add more assets</p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        openAssetSelectionDrawer(transaction.uid, "");
                      }}
                      className=" self-center  bg-card-bg2 border border-black  px-3 py-1 rounded-md mt-2 text-white  text-sm tracking-widest hover: hover:scale-105"
                    >
                      Select Asset
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
          <AssetSelectionDrawer
            isOpen={isAssetSelectionDrawerOpen}
            onClose={closeAssetSelectionDrawer}
            transactionUID={uidsForAddingAsset.transactionUID}
            assetUID={uidsForAddingAsset.assetUID}
            drawerHeading={"Select Asset"}
            assetSelectionTask={ASSET_SELECTION_TASK.TRANSACTION}
          />

          {userSettings?.isEoaSelected ? (
            <div className="flex justify-center item-center mt-10 pb-28 text-gray-500 text-sm ">
              <p>Batch transactions not available for EOA</p>
            </div>
          ) : (
            <div className="flex justify-center item-center mt-10 pb-28  ">
              <button
                onClick={() => {
                  navigate("/dashboard/transaction/add-address");
                }}
                className="relative"
              >
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <div className="flex flex-col justify-center items-center w-full bg-card-bg2  py-1 px-1 rounded-full">
                        <Plus
                          className="opacity-75 hover:opacity-100"
                          size={40}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className=" absolute  top-12 -right-24 bg-secondary-bg text-white border-black">
                      <p className="whitespace-nowrap">Add more addresses</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </button>
            </div>
          )}
        </div>

        <div className=" absolute bottom-2  flex justify-center items-center w-full z-40 ">
          <Button
            disabled={
              !validateTransferDetails(transferData) && !isProceedEnable
            }
            onClick={() => {
              handleProceedButton();
            }}
            size={"tsx"}
            variant={"tsxButton"}
            style={{
              opacity: isProceedEnable ? 1 : 0.5,
            }}
          >
            Proceed
          </Button>
        </div>

        <RemoveModal
          onCancel={closeRemoveAddressModal}
          onRemove={handleRemoveAddress}
          isOpen={isRemoveAddressModalOpen}
          message="Do you want to delete this transaction ? "
          actionBtnName="Delete"
        />
        <RemoveModal
          onCancel={closeRemoveTokenModal}
          onRemove={handelRemoveToken}
          isOpen={isRemoveTokenModalOpen}
          message="Do you want to remove the token?"
          actionBtnName="Remove"
        />
      </div>
    </PageTransitionWrapper>
  );
};

export default AddTokens;
