import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSdkError, buildApprovedNamespaces } from "@walletconnect/utils";
import toast from "react-hot-toast";

import VerifyPassword from "../../../components/Modals/VerifyPassword";
import ConnectScreen from "../../Dapp/components/ConnectScreen";

import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import {
  getUserSettingsData,
  log,
  setDappConnectionsData,
  getEIP1193Chains,
  getEIP1193Accounts,
  removeItemFromStorage,
} from "../../../utils/helper";
import { validateBiometric } from "../../../hooks/functional-hooks";
import { UserSettingsType } from "../../../constants/Types";
import {
  ERROR_EVENTS,
  DEVICE_AUTH_TYPE,
  STORAGE_KEYS,
} from "../../../constants/Enums";
import Loader from "../../../components/common/Loader";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";
import { useConfig } from "../../../context/ConfigProvider";

import { methods, events } from "../../../constants/eip1193";

type ConnectRequestDataType = {
  connect_title: string | null;
  connect_origin: string | null;
  connect_origin_icon?: string | null;
};

export default function DappConnect() {
  const [isLoading, setIsLoading] = useState(true);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");
  const [dappData, setDappData] = useState<ConnectRequestDataType>({
    connect_title: null,
    connect_origin: null,
    connect_origin_icon: null,
  });
  const biometricAuth = validateBiometric();
  const [activeSmartWallet, setActiveSmartWallet] =
    useState<UserSettingsType | null>(null);

  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();
  const [credId, setCredId] = useState<string>("");

  const {
    isInitialized,
    init,
    smartAccountAddress,
    provider,
    eoaAddress,
    chainID: chainId,
    userSettings,
  } = useWalletConfig();

  const { wcInstance } = useConfig();

  const { state } = useLocation();
  const { id, dappName, dappIcon, params } = state;

  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE | null>(
    null,
  );

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          setActiveSmartWallet(usersSettings[userIndex]);

          const { biometrics } = usersSettings[userIndex].settings.security;

          const { connect } =
            usersSettings[userIndex].settings.transactionSettings;

          setWalletAuthType(connect);

          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [walletAuthType, eoaAddress]);

  const getActiveAddress = () =>
    userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress;

  useEffect(() => {
    if (dappName && dappIcon)
      setDappData({
        connect_title: dappName,
        connect_origin: dappName,
        connect_origin_icon: dappIcon,
      });
  }, [dappName, dappIcon]);

  const rejectConnectionRequest = async () => {
    try {
      await wcInstance.rejectSession({
        id,
        reason: getSdkError("USER_REJECTED"),
      });

      toast.error("Connection Rejected");
      navigate("/dashboard");
    } catch (e: any) {
      toast.error("Connection Rejected");

      log("Error while rejection connection request ", { e }, "error");
      navigate("/dashboard");
    }
  };

  const handleError = () => {
    rejectConnectionRequest();
  };

  useEffect(() => {
    window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    return () => {
      window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    };
  });

  useEffect(() => {
    async function initializeSmartWallet() {
      if (!smartAccountAddress) {
        init({
          walletName: activeSmartWallet?.walletName || "",
          chainId,
        });
      }
    }

    setSmartWalletAddress(smartAccountAddress);

    initializeSmartWallet();
  }, [smartAccountAddress, smartWalletAddress]);

  const connectWallet = async () => {
    let isValid = false;

    if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
      isValid = await biometricAuth(credId);
    } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
      isValid = await verifyPassword();
    } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
      isValid = true;
    }

    if (!isValid) {
      rejectConnectionRequest();
      return;
    }

    try {
      const approvedNamespaces = buildApprovedNamespaces({
        proposal: params,
        supportedNamespaces: {
          eip155: {
            chains: getEIP1193Chains(),
            methods,
            events,
            accounts: getEIP1193Accounts(getActiveAddress()),
          },
        },
      });

      await wcInstance.approveSession({
        id,
        namespaces: approvedNamespaces,
      });

      const activeSessions = await wcInstance.getActiveSessions();

      const connections: any = {};

      Object.keys(activeSessions).forEach((sessionData: any) => {
        const session = activeSessions[sessionData];

        const account = session.namespaces.eip155.accounts[0].split(":")[2];

        if (Object.keys(connections).includes(account)) {
          connections[account].push({
            hostname: session.peer.metadata.url,
            name: session.peer.metadata.name,
            icon: session.peer.metadata.icons[0],
            description: session.peer.metadata.description,
            connectedWallet: account,
            topic: session.topic,
          });
        } else {
          connections[account] = [
            {
              hostname: session.peer.metadata.url,
              name: session.peer.metadata.name,
              icon: session.peer.metadata.icons[0],
              description: session.peer.metadata.description,
              connectedWallet: account,
              topic: session.topic,
            },
          ];
        }
      });

      await setDappConnectionsData(connections);

      toast.success("Connection successful");
      navigate("/dashboard");
    } catch (error) {
      try {
        await wcInstance.rejectSession({
          id,
          reason: getSdkError("USER_REJECTED"),
        });

        toast.error("Connection rejected");
        navigate("/dashboard");
      } catch (e) {
        toast.error("Unable to connect, try again later.");
        log("Error while rejection connection request ", { e }, "error");
        navigate("/dashboard");
      }
    }
  };

  useEffect(() => {
    if (
      isInitialized &&
      provider &&
      ((userSettings?.isEoaSelected && eoaAddress) || smartAccountAddress)
    ) {
      removeItemFromStorage(STORAGE_KEYS.WC_READ_STATUS);
      setIsLoading(false);
    }
  }, [
    isInitialized,
    smartAccountAddress,
    provider,
    userSettings?.isEoaSelected,
    eoaAddress,
  ]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <ConnectScreen
          connectOrigin={dappData.connect_origin}
          smartWalletAddress={getActiveAddress()}
          rejectConnectionRequest={rejectConnectionRequest}
          connectWallet={connectWallet}
          connectOriginIcon={dappData.connect_origin_icon}
        />
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
}
