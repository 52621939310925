/* eslint-disable import/prefer-default-export */
import axios from "axios";
import supportedChains from "../constants/chains";
import tokens from "../constants/tokens";
import {
  AdvertiserDetails,
  SupportedChainType,
  Tokens,
  CompletedTxnAPIType,
  DashboardAdAPIType,
} from "../constants/Types";
import * as config from "../config/env";
import MixPanel from "../utils/MixPanel";
import { MIXPANEL_KEY } from "../constants/Enums";

export const getChains = async (): Promise<SupportedChainType[]> => {
  try {
    const data = await axios.get(`${config.BASE_URL}/chains.json`);

    return data?.data?.supportedChains || supportedChains;
  } catch (error) {
    return supportedChains;
  }
};

export const getTokens = async (): Promise<Tokens> => {
  try {
    const data = await axios.get(`${config.BASE_URL}/tokens.json`);

    return data?.data || tokens;
  } catch (error) {
    return tokens;
  }
};

export const getAdvertiserDetails = async (): Promise<AdvertiserDetails> => {
  try {
    const response = await axios.get(
      `${config.BASE_API_URL}/advertiser/xtension-ad`,
      { headers: { extension: config.BASE_API_XTENSION_HEADER } },
    );

    return response?.data?.data;
  } catch (error) {
    return {
      advertiserAdUrl: config.DUMMY_AD_URL,
      advertiserWebsite: config.DUMMY_AD_WEBSITE,
      advertiserName: config.DUMMY_AD_NAME,
      advertiserChains: config.DUMMY_AD_CHAINS,
    };
  }
};

export const saveCompletedTxn = async ({
  data,
  address,
}: {
  data: CompletedTxnAPIType;
  address: string;
}): Promise<void> => {
  try {
    await axios.post(
      `${config.BASE_API_URL}/dashboard/v2/user/save-user-txns`,
      {
        ...data,
      },
      { headers: { address } },
    );
  } catch (error) {
    MixPanel.track(MIXPANEL_KEY.Backend_Update_Failed, {
      error,
      data,
    });
  }
};

export const getDashboardAdData = async (): Promise<DashboardAdAPIType[]> => {
  let data;

  try {
    data = await axios.get(
      `${config.BASE_API_URL}/advertiser/partnership-banners`,
    );
  } catch (error) {
    MixPanel.track(MIXPANEL_KEY.Backend_Update_Failed, {
      error,
    });
  }
  return data?.data.banners || [];
};

export const createUserData = async ({
  eoaAddress,
  smartAccountAddress,
  userName,
  referralCode,
}: {
  eoaAddress: string;
  smartAccountAddress: string;
  userName: string;
  referralCode?: string;
}) => {
  try {
    const url = `${config.BASE_API_URL}/user/data${
      referralCode ? `?referralCode=${referralCode}` : ""
    }`;

    await axios.post(url, {
      eoaAddress,
      smartAccountAddress,
      userName,
      role: "USER",
    });
  } catch (error) {
    MixPanel.track(MIXPANEL_KEY.Backend_Update_Failed, {
      error,
    });
  }
};

export const createNewSmartAccount = async ({
  eoaAddress,
  smartAccountAddress,
}: {
  eoaAddress: string;
  smartAccountAddress: string;
}) => {
  try {
    await axios.patch(`${config.BASE_API_URL}/user/data/add-swa`, {
      eoaAddress,
      smartAccountAddress,
    });
  } catch (error) {
    MixPanel.track(MIXPANEL_KEY.Backend_Update_Failed, {
      error,
    });
  }
};
