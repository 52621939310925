import { useState, useEffect, useRef, RefObject } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  customEventHandler,
  getChainDetails,
  getItemFromStorage,
  getUserSettingsData,
  isAddressSame,
  log,
  setAppVersion,
  setChains,
  setItemInStorage,
  removeItemFromStorage,
} from "../../utils/helper";
import { SupportedChainType, UserSettingsType } from "../../constants/Types";
import Loader from "../../components/common/Loader";
import { transferState } from "../../state/TransferState";
import amountAndBalanceState from "../../state/AmountAndBalanceState";
import gasState from "../../state/GasState";

import Header from "../../components/DashboardComponents/Header";
import TokenList from "../../components/DashboardComponents/TokenList";
import Store from "../../dapp-connection/js/components/StoreComponent";
import EthProvider from "../../dapp-connection/js/services/EthProvider";
import MixPanel from "../../utils/MixPanel";

import refreshIcon from "../../assets/refresh.svg";
import useWallet from "../../lib/store/hooks/useWallet";
import { getChains } from "../../utils/api";
import { swapBridgeState } from "../../state/SwapBridgeState";
import { supportedChainsState } from "../../state/SupportedChainsState";
import {
  CUSTOM_EVENTS,
  ENVIRONMENT,
  STORAGE_KEYS,
} from "../../constants/Enums";

import WarningMarquee from "../../components/DashboardComponents/WarningMarquee";
import { NODE_ENV } from "../../config/env";

function Dashboard() {
  const setTransferData = useSetRecoilState(transferState);
  const setSwapBridgeState = useSetRecoilState(swapBridgeState);
  const setAmountAndBalanceState = useSetRecoilState(amountAndBalanceState);
  const setGasState = useSetRecoilState(gasState);
  const setSupportedChains = useSetRecoilState(supportedChainsState);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [renderKey, setRenderKey] = useState(0);
  const [tokenListHeight, setTokenListHeight] = useState(0);
  const tokenListRef: RefObject<HTMLDivElement> = useRef(null);
  const [marqueeVisible, setMarqueeVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const smartAccountFromStorage = getItemFromStorage(
    STORAGE_KEYS.SMART_ACCOUNT,
  );
  const chainId = getItemFromStorage(STORAGE_KEYS.NETWORK);

  const {
    smartAccountAddress,
    init,
    isConnected,
    isInitialized,
    userSettings,
    eoaAddress,
  } = useWallet();

  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState<any>(0);

  const [allWallets, setAllWallets] = useState<UserSettingsType[] | null>(null);

  const chainDetails = getChainDetails(chainId);

  useEffect(() => {
    setAppVersion();
  }, []);

  useEffect(() => {
    const buySell = getItemFromStorage(STORAGE_KEYS.BUY_SELL);

    if (buySell) {
      navigate(`/${buySell.toLowerCase()}`);
      removeItemFromStorage(STORAGE_KEYS.BUY_SELL);
    }
  }, [chainId, smartAccountAddress]);

  const resetStates = () => {
    setTransferData([]);
    setAmountAndBalanceState([]);
    setGasState([]);
    setSupportedChains([]);
    setSwapBridgeState([]);
  };

  const handleRefresh = () => {
    if (tokenListRef.current) {
      const height = tokenListRef.current.offsetHeight;

      setTokenListHeight(height);
    }

    setRefresh(!refresh);
    resetStates();

    setTimeout(() => {
      setTokenListHeight(0);
    }, 2000);

    setRenderKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setTransferData([]);
    setAmountAndBalanceState([]);
    setGasState([]);
    setSwapBridgeState([]);

    async function getSupportedChains() {
      const chainsData: SupportedChainType[] = await getChains();

      setSupportedChains(chainsData);
      await setChains(chainsData);
    }
    getSupportedChains();

    const getAllWalletsFromIndexedDB = async () => {
      const allWalletsInIndexedDB: UserSettingsType[] =
        await getUserSettingsData();

      setAllWallets(allWalletsInIndexedDB);
    };

    getAllWalletsFromIndexedDB();
  }, [refresh]);

  useEffect(() => {
    const initializeSmartWallet = async () => {
      if (!allWallets || allWallets.length === 0) return;

      const storedSmartAddress = getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);

      const activeWallet: UserSettingsType =
        allWallets.find((wallet) =>
          isAddressSame(wallet.activeSmartAccountAddress, storedSmartAddress),
        ) ?? allWallets[0];

      if (
        !isAddressSame(
          activeWallet.activeSmartAccountAddress,
          storedSmartAddress,
        )
      ) {
        setItemInStorage(
          STORAGE_KEYS.SMART_ACCOUNT,
          activeWallet.activeSmartAccountAddress,
        );
      }

      // Initialize wallet
      if (isInitialized && isConnected) {
        setIsLoading(false);
        return;
      }

      await init({
        walletName: activeWallet.walletName,
        chainId,
      });

      // Register with MixPanel
      MixPanel.register({
        name: activeWallet.walletName || "",
        walletAddress: activeWallet.activeSmartAccountAddress || "",
      });
    };

    initializeSmartWallet().catch((error) =>
      log("Error while Initializing Smart Wallet", error),
    );
  }, [allWallets, chainId, isConnected, isInitialized, refresh]);

  useEffect(() => {
    async function dappConnect() {
      await setChains([]);
      const tempData: any = Store.getTempState();

      if (tempData?.shouldNotRedirect) {
        const data = {
          chainId,
          addresses: [smartAccountFromStorage || smartAccountAddress],
          nodeUri: chainDetails.rpc,
          method: tempData?.method,
        };

        EthProvider.extensionResponse(data);
      }
    }
    dappConnect();
  }, [chainId, smartAccountFromStorage, smartAccountAddress, refresh]);

  useEffect(() => {
    const tempData: any = Store.getTempState();

    if (!Object.keys(tempData).length)
      customEventHandler(
        {
          newAddress: userSettings?.isEoaSelected
            ? eoaAddress
            : smartAccountAddress,
        },
        CUSTOM_EVENTS.ACCOUNT_CHANGE,
      );
  }, [smartAccountAddress, refresh, userSettings?.isEoaSelected]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", () => {
        setScrollPosition(scrollRef.current?.scrollTop);
      });
    }
  }, [scrollRef]);

  useEffect(() => {
    if (NODE_ENV === ENVIRONMENT.PRODUCTION) {
      setMarqueeVisible(false);
    } else {
      setMarqueeVisible(true);
    }
  }, [NODE_ENV]);

  return (
    <>
      <div className="overflow-hidden">
        {marqueeVisible && <WarningMarquee />}
        <div
          ref={scrollRef}
          className="overflow-y-scroll h-[600px] bg-primary-bg"
        >
          <Header
            scrollPosition={scrollPosition}
            marqueeVisible={marqueeVisible}
            wcProgressStatus={!(isLoading || !isConnected)}
          />
          {isLoading || !isConnected ? (
            <></>
          ) : (
            <div className="px-2 select-none">
              <div
                className={`sticky float-left  w-full flex items-center gap-2 bg-primary-bg h-14 ${
                  marqueeVisible ? "top-[89px]" : "top-[59px]"
                }`}
              >
                <p className="font-semibold text-lg pl-4 py-2">Assets</p>
                <span className=" text-gray-200 rounded-full">
                  <img
                    onClick={handleRefresh}
                    className="h-4 ml-1 m-auto cursor-pointer hover:rotate-180 duration-1000"
                    src={refreshIcon}
                    alt="refresh btn"
                  />
                </span>
              </div>

              <div
                ref={tokenListRef}
                key={renderKey}
                style={{ minHeight: `${tokenListHeight}px` }}
                className="px-2"
              >
                <TokenList isImportTokenDrawerAvailable={true} />
              </div>
            </div>
          )}
        </div>
      </div>

      {isLoading ? <Loader /> : <></>}
    </>
  );
}

export default Dashboard;
