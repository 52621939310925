import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ethers, BigNumber } from "ethers";
import { toast } from "react-hot-toast";
import { BiconomySmartAccountV2, UserOperationStruct } from "@biconomy/account";
import { v4 as uuidv4 } from "uuid";

import { transferState } from "../../state/TransferState";
import { supportedChainsState } from "../../state/SupportedChainsState";
import usePasswordVerification from "../../hooks/functional-hooks/usePasswordVerification";
import {
  getRawTransactionObject,
  getShortDisplayString,
  constructTransactionData,
  constructFinalUserOp,
  getItemFromStorage,
  log,
  checkIsNativeToken,
  getNativeAssetGas,
  getExplorerTxUrl,
  setTransactionDataInLocal,
  formatDateFromTimestamp,
  formatUnixTimestamp,
  getDeveloperAdOption,
  getUserSettingsData,
  getUSDValue,
  getNativeAssetGasForEoa,
  getEoaSigner,
  checkIsMainnet,
} from "../../utils/helper";
import { saveCompletedTxn } from "../../utils/api";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";

import RemoveModal from "../../components/Modal";
import TransactionDrawer from "../../components/TransactionDrawer";
import VerifyPassword from "../../components/Modals/VerifyPassword";
import { validateBiometric } from "../../hooks/functional-hooks";
import {
  MIXPANEL_KEY,
  PAGE_TITLE_BUTTON_TYPE,
  STORAGE_KEYS,
  TRANSACTION_ERROR,
  TRANSACTION_STATUS,
  DEVICE_AUTH_TYPE,
  ASSET_SELECTION_TASK,
  GAS_FEE_TYPE,
} from "../../constants/Enums";
import {
  SelectedTokenForGasDefault,
  TransactionDrawerDetailsDefault,
} from "../../constants/TypesDefaults";
import {
  TransferStateType,
  SelectedTokenForGasType,
  RawTransactionObjectType,
  TransactionData,
  TransactionDrawerDetailsType,
  UserSettingsType,
  CompletedTxnAPIType,
} from "../../constants/Types";
import Loader from "../../components/common/Loader";
import GasManagement from "../../components/GasManagement";
import MixPanel from "../../utils/MixPanel";
import PageTitle from "../../components/PageTitle";
import AssetCard from "../../components/TransactionComponents/AssetCard";
import Web3AvatarComponent from "../../components/Web3AvatarComponent";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const ApproveTransaction = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [partialTransaction, setPartialTransaction] = useState<
    Partial<UserOperationStruct>
  >({ sender: "" });
  const [supportedChains] = useRecoilState(supportedChainsState);
  const [transferDataList, setTransferDataList] = useRecoilState(transferState);
  const [transactionInProcess, setTransactionInProcess] =
    useState<boolean>(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] =
    useState<boolean>(false);
  const [isCancelAllTransactionModalOpen, setIsCancelAllTransactionModalOpen] =
    useState<boolean>(false);
  const [transactionDrawerDetails, setTransactionDrawerDetails] =
    useState<TransactionDrawerDetailsType>(TransactionDrawerDetailsDefault);
  const [authError, setAuthError] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string>("");
  const [nativeAssetGas, setNativeAssetGas] = useState<string>("loading");
  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE>(
    DEVICE_AUTH_TYPE.PASSWORD,
  );
  const [credId, setCredId] = useState<string>("");

  const item = getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
  const [SCW] = useState(item || null);
  const [gasInUSD, setGasInUSD] = useState<number>(0);
  const [nativeAssetInUSD, setNativeAssetInUSD] = useState<number>(0);
  const [adURL, setAdURL] = useState<string | null>(null);

  const biometricAuth = validateBiometric();

  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();

  const navigate = useNavigate();
  const {
    getSmartAccountProvider,
    smartAccountAddress,
    chainID: chainId,
    eoaAddress,
    provider,
    userSettings,
    eoaPrivateKey,
  } = useWalletConfig();

  const [turnOffAds, setTurnOffAds] = useState(false);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          const { biometrics } = usersSettings[userIndex].settings.security;

          const { approveTransaction } =
            usersSettings[userIndex].settings.transactionSettings;

          setWalletAuthType(approveTransaction);
          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, []);

  useEffect(() => {
    async function getDeveloperOptions() {
      setTurnOffAds(await getDeveloperAdOption(eoaAddress));
    }
    getDeveloperOptions();
  }, [eoaAddress]);

  const [selectedTokenForGas, setSelectedTokenForGas] =
    useState<SelectedTokenForGasType>(SelectedTokenForGasDefault(chainId));

  // Progress bar logic
  const [progress, setProgress] = useState<number>(0);
  const [fillStarted, setFillStarted] = useState<boolean>(false);

  useEffect(() => {
    async function getGasInUSD() {
      if (nativeAssetGas !== "loading" && chainId === 1) {
        setIsLoading(true);
        const resp = await getUSDValue({
          cryptoAsset: "ETH",
          fiatCurrency: "USD",
        });

        setNativeAssetInUSD(resp);
        setIsLoading(false);
      }
    }

    getGasInUSD();
  }, [nativeAssetGas]);

  useEffect(() => {
    if (nativeAssetInUSD && nativeAssetGas !== "loading") {
      setGasInUSD(Number(nativeAssetGas) * nativeAssetInUSD);
    }
  }, [nativeAssetInUSD, nativeAssetGas]);

  useEffect(() => {
    const id = uuidv4();

    setTransactionId(id);

    MixPanel.track(MIXPANEL_KEY.Transaction_Initiated, {
      transactionId: id,
      chainId,
      transactions: transferDataList,
    });
  }, [transferDataList, transferDataList.length]);

  useEffect(() => {
    if (fillStarted) {
      const startTime = Date.now();

      const fillProgressBar = () => {
        const elapsedTime = Date.now() - startTime;

        if (elapsedTime >= 10000) {
          setProgress(90);
        } else {
          const percentage = (elapsedTime / 10000) * 90;

          setProgress(percentage);
          setTimeout(fillProgressBar, 50);
        }
      };

      fillProgressBar();
    }
  }, [fillStarted]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    // getNativeAssetGas(transferDataList);
    getNativeAssetGas(partialTransaction, setNativeAssetGas);
  }, [SCW, smartAccountAddress]);

  // Cancel the whole transaction
  const clearAllTransactions = () => {
    setTransferDataList([]);
    setIsCancelAllTransactionModalOpen(false);
    navigate("/dashboard");
  };

  const openCancelAllTransactionsModal = () => {
    setIsCancelAllTransactionModalOpen(true);
  };

  const closeCancelAllTransactionsModal = () => {
    setIsCancelAllTransactionModalOpen(false);
  };

  const shouldShowAd = (): boolean => {
    if (userSettings?.isEoaSelected) return false;

    if (
      supportedChains.find((chain) => chain.chainId === chainId) &&
      !supportedChains.find((chain) => chain.chainId === chainId)?.isMainnet
    )
      if (turnOffAds) return false;

    return true;
  };

  const sendEoaTransaction = async (
    finalUserOp: Partial<UserOperationStruct> & { value?: any },
    _isGasless: GAS_FEE_TYPE,
  ) => {
    try {
      const signer = getEoaSigner(eoaPrivateKey, provider);

      finalUserOp.value = BigNumber.from(finalUserOp?.value);

      const tx = await signer.sendTransaction(finalUserOp);
      const transactionDetails = await tx.wait();
      const { from } = transactionDetails;
      const { from: sender, status, ...rest } = transactionDetails;
      const updatedTransactionDetails = {
        sender,
        success: status === 1 ? "true" : "false",
        ...rest,
      };
      const currentTimestamp: number = Math.floor(Date.now() / 1000);
      const explorerUrl: string = getExplorerTxUrl(chainId);
      const rawTransaction: RawTransactionObjectType[] = [];

      transferDataList.forEach((data: TransferStateType) => {
        const obj = getRawTransactionObject(data, eoaAddress);

        obj.forEach((element: any) => {
          rawTransaction.push(element);
        });
      });

      for (let i = 0; i < rawTransaction.length; i += 1) {
        const currentAmount: string =
          rawTransaction[i]?.args[1]?.toString() ||
          rawTransaction[i]?.value?.toString() ||
          "0";
        const formattedAmount: string = ethers.utils
          .formatUnits(currentAmount, rawTransaction[i]?.decimal)
          .toString();

        const transactionData: TransactionData = {
          date: formatDateFromTimestamp(currentTimestamp),
          time: formatUnixTimestamp(currentTimestamp),
          transaction_id: transactionDetails.transactionHash,
          from_address: from,
          to_address: rawTransaction[i].to,
          nonce: tx.nonce.toString(),
          amount: formattedAmount.toString(),
          gas_used: transactionDetails.gasUsed.toString(),
          label: `Sent ${
            rawTransaction[i]?.tokenSymbol || rawTransaction[i]?.nftName
          }`,
          status: transactionDetails.status ? "1" : "0",
          explorer: explorerUrl,
          isGasless: _isGasless,
        };

        // eslint-disable-next-line no-await-in-loop
        await setTransactionDataInLocal(
          from || eoaAddress,
          chainId,
          transactionData,
        );
      }

      setProgress(100);
      setFillStarted(false);

      setTransactionDrawerDetails({
        transactionHash: transactionDetails.transactionHash,
        isError: false,
        data: transactionDetails,
      });

      MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
        transactionId,
        gasFeeType: _isGasless,
        gasFeeAsset:
          _isGasless === GAS_FEE_TYPE.GASLESS
            ? null
            : {
                name: selectedTokenForGas.tokenName,
                contractAddress: selectedTokenForGas.tokenAddress,
                value: selectedTokenForGas.tokenGasValue,
              },
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
        transactionHash: transactionDetails.transactionHash,
        transactionReceipt: updatedTransactionDetails,
        transactions: transferDataList,
      });

      const data: CompletedTxnAPIType = {
        eoaAddress,
        smartAccountAddress: transactionDetails.from,
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: transferDataList.length > 1,
        transactionHash: transactionDetails.transactionHash,
        status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
        gasFeeType: _isGasless,
        transactionId,
        adURL: _isGasless === GAS_FEE_TYPE.GASLESS ? adURL : null,
        receipt: updatedTransactionDetails,
        transactions: transferDataList,
      };

      saveCompletedTxn({
        data,
        address: from?.toLowerCase() || eoaAddress?.toLowerCase(),
      });

      setTimeout(() => setIsTransactionModalOpen(true), 500);
    } catch (e) {
      MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
        transactionId,
        gasFeeType: _isGasless,
        gasFeeAsset:
          _isGasless === GAS_FEE_TYPE.GASLESS
            ? null
            : {
                name: selectedTokenForGas.tokenName,
                contractAddress: selectedTokenForGas.tokenAddress,
                value: selectedTokenForGas.tokenGasValue,
              },
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        transactions: transferDataList,
      });

      const data: CompletedTxnAPIType = {
        eoaAddress,
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: transferDataList.length > 1,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        gasFeeType: _isGasless,
        transactionId,
        adURL: _isGasless === GAS_FEE_TYPE.GASLESS ? adURL : null,
        transactions: transferDataList,
      };

      saveCompletedTxn({
        data,
        address: eoaAddress?.toLowerCase(),
      });

      log("Error while sending batch txn : ", e, "error");
      setTransactionDrawerDetails({
        transactionHash: TRANSACTION_ERROR.TRANSACTION_FAILED,
        isError: true,
        data: e,
      });
      setProgress(0);
      setFillStarted(false);
      setIsTransactionModalOpen(true);
    }
  };

  const sendSCTransaction = async (
    finalUserOp: Partial<UserOperationStruct>,
    _isGasless: GAS_FEE_TYPE,
  ) => {
    try {
      const smartAccountProvider: BiconomySmartAccountV2 | null =
        await getSmartAccountProvider();

      if (!smartAccountProvider) throw new Error("Provider not found");

      const userOpResponse = await smartAccountProvider.sendUserOp(finalUserOp);
      const transactionDetails = await userOpResponse.wait();
      const currentTimestamp: number = Math.floor(Date.now() / 1000);

      const explorerUrl: string = getExplorerTxUrl(chainId);

      const rawTransaction: RawTransactionObjectType[] = [];

      transferDataList.forEach((data: TransferStateType) => {
        const obj = getRawTransactionObject(data, smartAccountAddress);

        obj.forEach((element: any) => {
          rawTransaction.push(element);
        });
      });

      for (let i = 0; i < rawTransaction.length; i += 1) {
        const currentAmount: string =
          rawTransaction[i]?.args[1]?.toString() ||
          rawTransaction[i]?.value?.toString() ||
          "0";
        const formattedAmount: string = ethers.utils
          .formatUnits(currentAmount, rawTransaction[i]?.decimal)
          .toString();

        const transactionData: TransactionData = {
          date: formatDateFromTimestamp(currentTimestamp),
          time: formatUnixTimestamp(currentTimestamp),
          transaction_id: transactionDetails.receipt.transactionHash,
          from_address: SCW || smartAccountAddress,
          to_address: rawTransaction[i].to,
          nonce: `${finalUserOp.nonce}`,
          amount: formattedAmount.toString(),
          gas_used: transactionDetails.actualGasUsed,
          label: `Sent ${
            rawTransaction[i]?.tokenSymbol || rawTransaction[i]?.nftName
          }`,
          status: transactionDetails.success ? "1" : "0",
          explorer: explorerUrl,
          isGasless: _isGasless,
        };

        // eslint-disable-next-line no-await-in-loop
        await setTransactionDataInLocal(
          SCW || smartAccountAddress,
          chainId,
          transactionData,
        );
      }

      setProgress(100);
      setFillStarted(false);

      setTransactionDrawerDetails({
        transactionHash: transactionDetails.receipt.transactionHash,
        isError: false,
        data: transactionDetails,
      });

      MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
        transactionId,
        gasFeeType: _isGasless,
        gasFeeAsset:
          _isGasless === GAS_FEE_TYPE.GASLESS
            ? null
            : {
                name: selectedTokenForGas.tokenName,
                contractAddress: selectedTokenForGas.tokenAddress,
                value: selectedTokenForGas.tokenGasValue,
              },
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
        transactionHash: transactionDetails.receipt.transactionHash,
        transactionReceipt: transactionDetails,
        transactions: transferDataList,
      });

      const data: CompletedTxnAPIType = {
        eoaAddress,
        smartAccountAddress: SCW || smartAccountAddress,
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: transferDataList.length > 1,
        transactionHash: transactionDetails.receipt.transactionHash,
        status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
        gasFeeType: _isGasless,
        transactionId,
        adURL: _isGasless ? adURL : null,
        receipt: transactionDetails,
        transactions: transferDataList,
      };

      saveCompletedTxn({
        data,
        address: SCW?.toLowerCase() || smartAccountAddress?.toLowerCase(),
      });

      setTimeout(() => setIsTransactionModalOpen(true), 500);
    } catch (e) {
      MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
        transactionId,
        gasFeeType: _isGasless,
        gasFeeAsset:
          _isGasless === GAS_FEE_TYPE.GASLESS
            ? null
            : {
                name: selectedTokenForGas.tokenName,
                contractAddress: selectedTokenForGas.tokenAddress,
                value: selectedTokenForGas.tokenGasValue,
              },
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        transactions: transferDataList,
      });

      const data: CompletedTxnAPIType = {
        eoaAddress,
        smartAccountAddress: SCW || smartAccountAddress,
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: transferDataList.length > 1,
        status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
        gasFeeType: _isGasless,
        transactionId,
        adURL,
        transactions: transferDataList,
      };

      saveCompletedTxn({
        data,
        address: SCW?.toLowerCase() || smartAccountAddress?.toLowerCase(),
      });

      log("Error while sending batch txn : ", e, "error");
      setTransactionDrawerDetails({
        transactionHash: TRANSACTION_ERROR.TRANSACTION_FAILED,
        isError: true,
        data: e,
      });
      setProgress(0);
      setFillStarted(false);
      setIsTransactionModalOpen(true);
    }
  };

  const sendBatchTransaction = async () => {
    try {
      MixPanel.track(MIXPANEL_KEY.Transaction_Broadcasted, {
        transactionId,
        gasFeeType: checkIsNativeToken(selectedTokenForGas.tokenAddress)
          ? GAS_FEE_TYPE.COIN
          : GAS_FEE_TYPE.TOKEN,
        gasFeeAsset: {
          name: selectedTokenForGas.tokenName,
          contractAddress: selectedTokenForGas.tokenAddress,
          value: selectedTokenForGas.tokenGasValue,
        },
        chainId,
        transactions: transferDataList,
      });

      let isValid = false;

      if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
        isValid = await verifyPassword();
        return;
      } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
        isValid = true;
      }

      if (!isValid) {
        setAuthError("Authentication Failed");
        return;
      }

      setTransactionInProcess(true);
      setFillStarted(true);

      let finalUserOp: Partial<UserOperationStruct> = partialTransaction;

      if (userSettings?.isEoaSelected) {
        await sendEoaTransaction(finalUserOp, GAS_FEE_TYPE.COIN);
      } else {
        const smartAccountProvider: BiconomySmartAccountV2 | null =
          await getSmartAccountProvider();

        if (!smartAccountProvider)
          throw new Error("Unable to initialize SC provider");

        if (!checkIsNativeToken(selectedTokenForGas.tokenAddress)) {
          finalUserOp = await constructFinalUserOp(
            smartAccountProvider,
            finalUserOp,
            selectedTokenForGas.tokenAddress,
          );
        }

        await sendSCTransaction(
          finalUserOp,
          checkIsNativeToken(selectedTokenForGas.tokenAddress)
            ? GAS_FEE_TYPE.COIN
            : GAS_FEE_TYPE.TOKEN,
        );
      }
    } catch (e) {
      toast.error("Transaction was cancelled.");

      MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
        transactionId,
        gasFeeType: checkIsNativeToken(selectedTokenForGas.tokenAddress)
          ? GAS_FEE_TYPE.COIN
          : GAS_FEE_TYPE.TOKEN,
        gasFeeAsset: {
          name: selectedTokenForGas.tokenName,
          contractAddress: selectedTokenForGas.tokenAddress,
          value: selectedTokenForGas.tokenGasValue,
        },
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_CANCELLED,
        transactions: transferDataList,
      });

      log("Error while making txn : ", e, "error");
      setTransactionDrawerDetails({
        transactionHash: TRANSACTION_ERROR.TRANSACTION_CANCELLED,
        isError: true,
        data: e,
      });
      setProgress(0);
      setFillStarted(false);
      setIsTransactionModalOpen(true);
    }
  };

  const sendGaslessTransaction = async (
    gaslessTransactionOp: Partial<UserOperationStruct>,
  ) => {
    try {
      MixPanel.track(MIXPANEL_KEY.Transaction_Broadcasted, {
        transactionId,
        gasFeeType: GAS_FEE_TYPE.GASLESS,
        gasFeeAsset: null,
        chainId,
        transactions: transferDataList,
      });

      let isValid = false;

      if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
        isValid = await verifyPassword();
      } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
        isValid = true;
      }

      if (!isValid) {
        setAuthError("Authentication Failed");
        return;
      }

      setTransactionInProcess(true);
      setFillStarted(true);

      await sendSCTransaction(gaslessTransactionOp, GAS_FEE_TYPE.GASLESS);
    } catch (e) {
      toast.error("Gasless Transaction was cancelled.");

      MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
        transactionId,
        gasFeeType: GAS_FEE_TYPE.GASLESS,
        gasFeeAsset: null,
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_CANCELLED,
        transactions: transferDataList,
      });

      const data: CompletedTxnAPIType = {
        eoaAddress,
        smartAccountAddress: SCW || smartAccountAddress,
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: transferDataList.length > 1,
        status: TRANSACTION_STATUS.TRANSACTION_CANCELLED,
        gasFeeType: GAS_FEE_TYPE.GASLESS,
        transactionId,
        adURL,
        transactions: transferDataList,
      };

      saveCompletedTxn({
        data,
        address: SCW?.toLowerCase() || smartAccountAddress?.toLowerCase(),
      });

      log("Error while making gasless txn : ", e, "error");
      setTransactionDrawerDetails({
        transactionHash: TRANSACTION_ERROR.TRANSACTION_CANCELLED,
        isError: true,
        data: e,
      });
      setProgress(0);
      setFillStarted(false);
      setIsTransactionModalOpen(true);
    }
  };

  const buildPartialTransaction = async (transferData: TransferStateType[]) => {
    const rawTransaction: any[] = [];

    transferData.forEach((data: TransferStateType) => {
      const obj = getRawTransactionObject(data, smartAccountAddress);

      obj.forEach((element: any) => {
        rawTransaction.push(element);
      });
    });

    const txns = constructTransactionData(rawTransaction);

    const smartAccountProvider: BiconomySmartAccountV2 | null =
      await getSmartAccountProvider();

    if (!smartAccountProvider)
      throw new Error("Unable to initialize SC provider");

    const partialUserOp: Partial<UserOperationStruct> =
      await smartAccountProvider.buildUserOp(txns);

    setPartialTransaction(partialUserOp);
    getNativeAssetGas(partialUserOp, setNativeAssetGas);
  };

  const buildEoaPartialTransaction = async (
    transferData: TransferStateType[],
  ) => {
    const rawTransaction: any[] = [];

    transferData.forEach((data: TransferStateType) => {
      const obj = getRawTransactionObject(data, eoaAddress);

      obj.forEach((element: any) => {
        rawTransaction.push(element);
      });
    });

    const txns = constructTransactionData(rawTransaction);

    const partialUserOp: Partial<UserOperationStruct> = txns[0];

    setPartialTransaction(partialUserOp);

    getNativeAssetGasForEoa(provider, partialUserOp, setNativeAssetGas);
  };

  if (transferDataList.length === 0) navigate("/dashboard");

  useEffect(() => {
    async function buildAndSetPartialData() {
      // clearInterval(providerTimer);

      if (transferDataList && transferDataList.length) {
        try {
          if (userSettings?.isEoaSelected) {
            await buildEoaPartialTransaction(transferDataList);
          } else {
            await buildPartialTransaction(transferDataList);
          }
        } catch (e: any) {
          log(
            "Error while building partial txn : ",
            { e, msg: e?.message },
            "error",
          );
        }
      }
    }

    buildAndSetPartialData();
  }, [transferDataList, transferDataList.length, userSettings?.isEoaSelected]);

  if (transferDataList.length === 0) navigate("/dashboard");

  useEffect(() => {
    setIsLoading(true);
    if (partialTransaction.sender !== "" && provider && smartAccountAddress) {
      setIsLoading(false);
    }
  }, [
    partialTransaction,
    partialTransaction.sender,
    smartAccountAddress,
    provider,
  ]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PageTransitionWrapper>
            <div
              className={` ${
                isTransactionModalOpen ? "opacity-60" : ""
              } h-full w-full relative  mx-auto  bg-primary-bg overflow-hidden`}
            >
              <PageTitle
                title="Review"
                leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
                leftButtonToolTip="Edit"
                leftButtonToolTipCss="fixed top-2 left-5 bg-secondary-bg text-white border-black "
                leftDestinationRoute="/dashboard/transaction/add-tokens"
                isLeftButtonDisabled={transactionInProcess}
                rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.X}
                rightButtonToolTip="Cancel"
                rightButtonToolTipCss="fixed top-0 right-5 bg-secondary-bg text-white border-black"
                isRightButtonDisabled={transactionInProcess}
                onRightButtonClick={() => openCancelAllTransactionsModal()}
              />

              <div className=" max-w-[325px] mx-auto">
                <div className=" overflow-y-scroll  max-h-[415px] px-2">
                  {transferDataList.map((transaction) => (
                    <div className="my-2" key={transaction.uid}>
                      <div className="flex flex-col gap-2 bg-secondary-bg max-w-[325px] border rounded-xl py-2 px-2 border-black mx-auto mt-1 text-white shadow-md text-md">
                        <div className=" flex  justify-between   bg-card-bg2 border border-black py-2 px-2 rounded-lg z-30">
                          <div className="flex justify-center item-center gap-2">
                            <Web3AvatarComponent
                              address={transaction.address}
                              className=" w-7 h-7"
                            />
                            {transaction.name === "" &&
                            transaction.usersDomainName === "" ? (
                              <p className="font-medium">
                                {getShortDisplayString(transaction.address)}
                              </p>
                            ) : (
                              <div className="flex  gap-3 font-medium">
                                <p>
                                  {transaction.name !== ""
                                    ? transaction.name
                                    : transaction.usersDomainName}
                                </p>
                                <p className="text-sm my-auto">
                                  ({getShortDisplayString(transaction.address)})
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        {transaction.assets &&
                          transaction.assets.length > 0 && (
                            <div>
                              {transaction.assets.map((asset, idx: number) => (
                                <AssetCard
                                  assetCardSelectionTask={
                                    ASSET_SELECTION_TASK.TRANSACTION
                                  }
                                  asset={asset}
                                  transactionUID={transaction.uid}
                                  isProceedEnable={(bool: boolean) => {
                                    log(`Proceeding ${bool}`);
                                  }}
                                  isReview={true}
                                  key={idx}
                                />
                              ))}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className={` absolute   flex flex-col justify-center items-center w-full bottom-0 pb-2`}
              >
                <GasManagement
                  transaction={{
                    transactionId,
                    transactionInProcess,
                    progressBar: progress,
                  }}
                  selectedTokenForGas={selectedTokenForGas}
                  setSelectedTokenForGas={setSelectedTokenForGas}
                  isDappTransaction={false}
                  transferDataList={transferDataList}
                  partialTransaction={partialTransaction}
                  sendGaslessTransaction={sendGaslessTransaction}
                  sendBatchTransaction={sendBatchTransaction}
                  nativeAssetGas={nativeAssetGas}
                  shouldShowAd={shouldShowAd}
                  gasInUSD={gasInUSD}
                  authError={authError}
                  setAdURL={setAdURL}
                />
              </div>
            </div>
            <RemoveModal
              isOpen={isCancelAllTransactionModalOpen}
              onCancel={closeCancelAllTransactionsModal}
              onRemove={clearAllTransactions}
              message="Do you want to delete all the transactions?"
              actionBtnName="Delete"
            />
            <RemoveModal
              isOpen={isCancelAllTransactionModalOpen}
              onCancel={closeCancelAllTransactionsModal}
              onRemove={clearAllTransactions}
              message="Do you want to delete all the transactions?"
              actionBtnName="Delete"
            />
            <TransactionDrawer
              isOpen={isTransactionModalOpen}
              transactionDetails={transactionDrawerDetails}
            />

            <VerifyPassword
              isOpen={isVerifyPassModalOpen}
              isVerifying={isVerifying}
              showPasswordError={showPasswordError}
            />
          </PageTransitionWrapper>
        </>
      )}
    </>
  );
};

export default ApproveTransaction;
