import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ShieldOff, Edit2, Maximize, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import worldIcon from "../../assets/world.svg";
import worldConnectIcon from "../../assets/world-connect.svg";
import copy from "../../assets/copy.svg";
import send from "../../assets/diagonal-right-up-arrow.svg";
import dashboard from "../../assets/dashboard.svg";
import angleDown from "../../assets/angle-down.svg";
import {
  getActiveUserDevice,
  getDevicesFromStorage,
  getItemFromStorage,
  getNetworkErrorMessage,
  getUserSettingsData,
  isAddressSame,
  log,
  setItemInStorage,
  copyToClipboard,
  getAccentColor,
  getShortDisplayString,
  setUserSettingsData,
  removeItemFromStorage,
} from "../../utils/helper";
import { UserSettingsType } from "../../constants/Types";
import * as Config from "../../config/env";

import Store from "../../dapp-connection/js/components/StoreComponent";
import EthProvider from "../../dapp-connection/js/services/EthProvider";
import {
  STORAGE_KEYS,
  ERROR_EVENTS,
  CUSTOM_EVENTS,
  DAPP_INTERACTION_METHODS,
  STORAGE_NAME,
} from "../../constants/Enums";
import BackUpWallet from "../Modals/BackUpWallet";
import SecureYourWallet from "../Modals/SecureYourWallet";

import Web3AvatarComponent from "../Web3AvatarComponent";
import useWallet from "../../../src/lib/store/hooks/useWallet";
import { useConfig } from "../../context/ConfigProvider";
import SwitchNetwork from "./SwitchNetwork";
import Modal from "../common/Modal";
import QrReader from "../QrScanner";

import { Button } from "../ui/button";
import LoaderComponent from "../common/LoaderComponent";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

type HeaderProps = {
  scrollPosition: number;
  marqueeVisible: boolean;
  wcProgressStatus: boolean;
};

export default function Header({
  scrollPosition,
  marqueeVisible,
  wcProgressStatus,
}: HeaderProps) {
  const [storeData, setStoreData] = useState<any>(Store.getState());
  const [defaultChainId] = useState<number>(Config.DEFAULT_NETWORK.CHAIN_ID);
  const {
    eoaAddress,
    init,
    logout,
    userSettings,
    chainData,
    updateUserSettings,
  } = useWallet();
  const { wcInstance } = useConfig();

  const [isBackUpWalletModalOpen, setIsBackUpWalletModalOpen] =
    useState<boolean>(false);
  const [isSecureYourWalletModalOpen, setIsSecureYourWalletModalOpen] =
    useState<boolean>(false);
  const [isWalletSecured, setIsWalletSecured] = useState<boolean>(false);
  const editWalletNameInput = useRef(null);
  const [showEditWalletName, setShowEditWalletName] = useState<boolean>(false);
  const [showEditIconWallet, setShowEditIconWallet] = useState<boolean>(false);
  const [showEditIconSmartAccount, setShowEditIconSmartAccount] =
    useState<boolean>(false);
  const editAccountNameInput = useRef(null);
  const [showEditAccountName, setShowEditAccountName] =
    useState<boolean>(false);
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");

  const [activeWallet, setActiveWallet] = useState<UserSettingsType | null>(
    null,
  );
  const storageChainId = getItemFromStorage(STORAGE_KEYS.NETWORK);

  const smartAccountAddress = getItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
  const signerAccountAddress = getItemFromStorage(STORAGE_KEYS.SIGNER);
  const [activeSmartAccountName, setActiveSmartAccountName] =
    useState<string>("");

  const navigate = useNavigate();
  const [accentColor, setAccentColor] = useState("#000000");
  const [copyClicked, setCopyClicked] = useState(false);
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [isLoadingQr, setIsLoadingQr] = useState(false);
  const [isConnectIcon] = useState(false);

  const iconVariants = {
    hidden: { x: -10, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const fetchTheme = async () => {
    const theme = await getAccentColor(chainData?.chainId);

    setAccentColor(theme);
  };

  useEffect(() => {
    fetchTheme();
  }, [chainData?.chainId]);

  // TODO - Remove this useEffect after 3 versions are released
  useEffect(() => {
    const deviceInStorage = getDevicesFromStorage();

    if (deviceInStorage && deviceInStorage.length) {
      logout();
    }
  }, []);

  const handleAccountChange = (data: any) => {
    const tempData: any = Store.getTempState();

    if (!Object.keys(tempData).length)
      EthProvider.extensionResponseForChangeAddress({
        method: DAPP_INTERACTION_METHODS.CHANGE_ADDRESS,
        newAddress:
          data?.detail?.newAddress || userSettings?.isEoaSelected
            ? eoaAddress
            : smartAccountAddress,
      });
  };

  const handleError = async () => {
    const errorChainId = getItemFromStorage(STORAGE_KEYS.NETWORK);
    const newChain =
      getItemFromStorage(STORAGE_KEYS.OLD_NETWORK) ||
      Config.DEFAULT_NETWORK.CHAIN_ID;

    await setItemInStorage(STORAGE_KEYS.NETWORK, newChain);

    init({ walletName: activeWallet?.walletName || "", chainId: newChain });

    if (newChain !== errorChainId)
      toast.error(getNetworkErrorMessage(errorChainId, newChain));
    else {
      toast.error("Something went wrong! Please login again!");
      logout();
    }
  };

  useEffect(() => {
    window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    window.addEventListener(CUSTOM_EVENTS.ACCOUNT_CHANGE, handleAccountChange);
    return () => {
      window.removeEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
      window.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT_CHANGE,
        handleAccountChange,
      );
    };
  }, []);

  const handleDisableReminder = async (isSecurity: boolean) => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        if (isSecurity) {
          usersSettings[userIndex].settings.securityReminderTime = Number(
            new Date(),
          );
        } else {
          usersSettings[userIndex].settings.recoveryReminderTime = Number(
            new Date(),
          );
        }

        await setUserSettingsData(usersSettings);
      }
    } catch (error) {
      log("Error while reminding later:", error);
    }
  };

  const showBackUpWalletModal = (setReminderAfterHour?: boolean) => {
    if (isBackUpWalletModalOpen && setReminderAfterHour) {
      handleDisableReminder(false);
    }

    setIsBackUpWalletModalOpen(!isBackUpWalletModalOpen);
  };

  const showSecureYourWalletModal = (setReminderAfterHour?: boolean) => {
    if (isBackUpWalletModalOpen && setReminderAfterHour) {
      handleDisableReminder(true);
    }

    setIsSecureYourWalletModalOpen(!isSecureYourWalletModalOpen);
  };

  const showWalletConnectQrModal = (state: boolean) => {
    setShowQrScanner(state);
  };

  const setUserDevices = async () => {
    const addressToUse = eoaAddress || signerAccountAddress;

    if (!addressToUse) {
      return;
    }

    const activeWalletInUserSettings: UserSettingsType =
      await getActiveUserDevice(addressToUse);

    const activeSmartAccName = activeWalletInUserSettings?.accounts.find(
      (acc) => acc.address === smartAccountAddress,
    )?.name;

    if (activeWalletInUserSettings.isEoaSelected) {
      setActiveSmartAccountName(`${getShortDisplayString(addressToUse, true)}`);
    } else {
      setActiveSmartAccountName(activeSmartAccName ?? "");
    }

    setActiveWallet(activeWalletInUserSettings);
  };

  useEffect(() => {
    setUserDevices();
  }, [
    eoaAddress,
    userSettings?.isEoaSelected,
    signerAccountAddress,
    smartAccountAddress,
  ]);

  useEffect(() => {
    async function initializeSmartWallet() {
      if (!smartAccountAddress) {
        init({
          walletName: activeWallet?.walletName || "",
          chainId: storageChainId || defaultChainId,
        });
      }
    }

    setSmartWalletAddress(smartAccountAddress);

    initializeSmartWallet();

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [smartAccountAddress, smartWalletAddress]);

  // Dapp Connection Methods
  const disconnectDapp = () => {
    if (storeData?.connect_origin) {
      Store.saveState({ connect_title: false, connect_origin: false });

      EthProvider.respond(
        1,
        {
          method: DAPP_INTERACTION_METHODS.DISCONNECT,
          params: [
            userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress,
          ],
        },
        DAPP_INTERACTION_METHODS.DISCONNECT,
      );
    }
  };

  useEffect(() => {
    const data = Store.getState();

    setStoreData(data);
  }, [
    Store.getState().connect_origin,
    Store.getState().connect_title,
    storeData.connect_origin,
    storeData.connect_title,
  ]);

  // Backwards Compatibility for old users
  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        if (!usersSettings || usersSettings.length === 0) {
          logout();
          return;
        }

        const userIndex = usersSettings.findIndex((user: UserSettingsType) =>
          isAddressSame(user.address || "", eoaAddress || ""),
        );

        if (userIndex !== -1) {
          const { recoveryPhrase, security, recoveryReminderTime } =
            usersSettings[userIndex].settings;

          if (!recoveryPhrase.seedPhraseBackedUp) {
            if (
              !recoveryReminderTime ||
              (recoveryReminderTime &&
                recoveryReminderTime + 1 * 60 * 60 * 1000 < Number(new Date()))
            )
              setIsBackUpWalletModalOpen(true);
          }

          if (!security.password && !security.biometrics) {
            setIsWalletSecured(false);
          } else {
            setIsWalletSecured(true);
          }
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [
    eoaAddress,
    userSettings?.isEoaSelected,
    smartAccountAddress,
    smartWalletAddress,
  ]);

  useEffect(() => {
    const closeDrawerOnOutsideClick = (e: any) => {
      if (
        !(editAccountNameInput.current as any)?.contains(e.target) &&
        showEditAccountName
      ) {
        setShowEditAccountName(false);
      }

      if (
        !(editWalletNameInput.current as any)?.contains(e.target) &&
        showEditWalletName
      ) {
        setShowEditWalletName(false);
      }
    };

    document.addEventListener("mousedown", closeDrawerOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    };
  });

  const interpolate = (
    start: number,
    end: number,
    speed = 1,
    yPositionParam = false,
  ) => {
    if (yPositionParam && marqueeVisible) {
      end += 30;
      start += 30;
    }

    const clampedScrollPosition = Math.min(Math.max(scrollPosition, 0), 200);
    const adjustedScrollPosition = clampedScrollPosition * speed;
    const clampedAdjustedScrollPosition = Math.min(adjustedScrollPosition, 200);
    const ratio = clampedAdjustedScrollPosition / 200;

    return start + (end - start) * ratio;
  };

  const getAddress = () =>
    activeWallet?.isEoaSelected
      ? activeWallet.address
      : smartAccountAddress || smartWalletAddress;

  const parentClicked = (e: any, isAvatar: boolean) => {
    e.preventDefault();
    if (showEditAccountName || showEditWalletName) return;

    if (isAvatar || e.target.ariaLabel !== "editSmartAccountName") {
      navigate("/dashboard/select-an-address");
    }
  };

  async function handleSubmitQrUri(e: any) {
    try {
      setIsLoadingQr(true);
      e.preventDefault();
      const url = e.target.uri.value;

      await wcInstance.core.pairing.pair({ uri: url });
      setIsLoadingQr(false);
      showWalletConnectQrModal(false);
    } catch (err: any) {
      setIsLoadingQr(false);

      log("Wallet connect connection error", err, "error");

      toast.error(
        "Error while connecting with Wallet connect. Try again later!",
      );
    }
  }

  useEffect(() => {
    const wcUri = getItemFromStorage(
      STORAGE_KEYS.WC_CONNECT,
      STORAGE_NAME.SESSION_STORAGE,
    );
    const wcRead = getItemFromStorage(STORAGE_KEYS.WC_READ_STATUS);

    if (!wcRead && activeWallet && wcUri && wcInstance && wcProgressStatus) {
      setItemInStorage(STORAGE_KEYS.WC_READ_STATUS, true);
      removeItemFromStorage(
        STORAGE_KEYS.WC_CONNECT,
        STORAGE_NAME.SESSION_STORAGE,
      );
      handleSubmitQrUri({
        preventDefault: () => {
          log("Prevent default from QR scanner");
        },
        target: {
          uri: {
            value: wcUri,
          },
        },
      });
    }
  }, [activeWallet, wcInstance, wcProgressStatus]);

  const qrModalChild = () => {
    if (isLoadingQr) {
      return <LoaderComponent />;
    }

    return (
      <div className="align-center flex flex-col justify-center">
        <QrReader onScanSuccessful={handleSubmitQrUri} />
        <p className="text-[12px] p-2">
          Can't scan the QR?<br></br>
          <span className="underline pt-1">
            Paste your connection URI below and Connect
          </span>
        </p>

        <form
          className="align-center flex flex-col justify-center gap-4"
          onSubmit={(e) => handleSubmitQrUri(e)}
        >
          <input
            name="uri"
            id="uri"
            type="text"
            placeholder="Enter WalletConnect URI"
            className="p-2 m-2 text-[12px] text-black focus:outline-black"
          />
          <Button
            type="submit"
            variant={"default"}
            className=" bg-green-500 hover:bg-green-500 hover:bg-opacity-80"
          >
            Connect
          </Button>
        </form>
      </div>
    );
  };

  const updateNameInUserSettings = async (
    _name: string,
    isEoa: boolean,
    _address?: string,
  ) => {
    const usersSettings: UserSettingsType[] = await getUserSettingsData();
    const userIndex = usersSettings.findIndex(
      (user: UserSettingsType) => user.address === eoaAddress,
    );

    if (userIndex !== -1) {
      if (isEoa) {
        usersSettings[userIndex].walletName = _name;
        await setUserSettingsData(usersSettings);
        updateUserSettings(usersSettings[userIndex]);
        setShowEditWalletName(false);
      } else {
        usersSettings[userIndex].accounts.forEach((smartAccount) => {
          if (isAddressSame(smartAccount.address, _address || "")) {
            smartAccount.name = _name;
          }
        });
        await setUserSettingsData(usersSettings);
        updateUserSettings(usersSettings[userIndex]);
        setShowEditAccountName(false);
      }
    } else {
      log("User not found");
    }

    await setUserDevices();
  };

  const editNameBox = (isWallet: boolean) => {
    if (isWallet) setShowEditWalletName(!showEditWalletName);
    else setShowEditAccountName(!showEditAccountName);
  };

  const handleUpdateName = (e: any, isWallet: boolean) => {
    if (isWallet) {
      updateNameInUserSettings(e.target.value, isWallet);
    } else {
      updateNameInUserSettings(
        e.target.value,
        isWallet,
        smartAccountAddress || smartWalletAddress,
      );
    }
  };

  return (
    <>
      <nav
        className={`flex flex-col items-center pt-3 text-white rounded-b-xl w-full h-fit bg-secondary-bg shadow-md shadow-black select-none
          ${marqueeVisible ? "mt-[30px]" : "mt-[0px]"}
          `}
      >
        <div
          className={`absolute left-0 w-full h-[60px] bg-secondary-bg rounded-b-xl ${
            marqueeVisible ? "top-[29px]" : "top-0"
          }`}
          style={{
            opacity: `${interpolate(0, 1, 3)}`,
          }}
        ></div>
        <SwitchNetwork marqueeVisible={marqueeVisible} />
        <motion.div className="absolute top-0 left-0 flex items-start w-full h-fit px-4 pt-3">
          <div
            className="mx-auto w-[100%] h-fit font-bold flex flex-col gap-3 items-center"
            style={{
              paddingTop: `${interpolate(3, 0.5)}rem`,
            }}
          >
            <Web3AvatarComponent
              style={{
                height: "70px",
                width: "70px",
                scale: interpolate(1, 0, 1.5),
                y: `${interpolate(0, -55, 1.5, true)}px`,
              }}
              className="rounded-full object-cover cursor-pointer z-20"
              address={getAddress()}
              onClickAvatar={(e: any) => parentClicked(e, true)}
            />
            <motion.div
              style={{
                y: `${interpolate(0, -82, 1.8, true)}px`,
                x: `${interpolate(-3, 0)}px`,
                fontSize: `${interpolate(1.167, 0.889)}rem`,
              }}
              className="text-center w-fit max-w-[200px] truncate cursor-pointer flex items-center z-20"
            >
              <Web3AvatarComponent
                address={getAddress()}
                className="h-5 w-5 rounded-full mr-2"
                style={{
                  x: `${interpolate(-3, 0)}px`,
                  scale: `${interpolate(0, 1)}`,
                  opacity: scrollPosition > 128 ? `${interpolate(-1, 1)}` : 0,
                }}
              />

              <motion.div
                className="flex relative"
                onHoverStart={() => {
                  if (!userSettings?.isEoaSelected) {
                    setShowEditIconSmartAccount(true);
                  }
                }}
                onHoverEnd={() => setShowEditIconSmartAccount(false)}
                tabIndex={0}
                onFocus={() => {
                  if (!userSettings?.isEoaSelected) {
                    setShowEditIconSmartAccount(true);
                  }
                }}
                onBlur={() => setShowEditIconSmartAccount(false)}
              >
                {showEditAccountName ? (
                  <input
                    ref={editAccountNameInput}
                    type="text"
                    placeholder={activeSmartAccountName}
                    className="w-[100px] h-[25px] bg-transparent text-sm h-9 flex justify-center items-center z-10 border-b focus:outline-none focus:border-b-2"
                    width="100px"
                    height="25px"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleUpdateName(e, false);

                      if (e.key === "Escape") editNameBox(false);
                    }}
                  ></input>
                ) : (
                  <motion.p
                    className="flex justify-center relative"
                    onClick={(e) => {
                      if (userSettings?.isEoaSelected) parentClicked(e, true);
                    }}
                  >
                    {activeSmartAccountName?.length > 15
                      ? `${activeSmartAccountName.substr(0, 10)}...`
                      : activeSmartAccountName}
                  </motion.p>
                )}

                <motion.div className="z-50 my-auto ">
                  <AnimatePresence>
                    {(showEditIconSmartAccount || showEditAccountName) && (
                      <motion.div
                        className=""
                        variants={iconVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        transition={{ duration: 0.2 }}
                      >
                        {showEditAccountName ? (
                          <X
                            aria-label="closeEditSmartAccountName"
                            className=" pl-2 cursor-pointer hover:scale-110"
                            onClick={() => editNameBox(false)}
                            style={{
                              marginLeft: `${interpolate(0.25, 0.2)}rem`,
                              height: `${interpolate(1.5, 1.2)}rem`,
                            }}
                          />
                        ) : (
                          <Edit2
                            aria-label="editSmartAccountName"
                            className="pl-2 py-1 cursor-pointer hover:scale-110"
                            onClick={() => editNameBox(false)}
                            style={{
                              marginLeft: `${interpolate(0.25, 0.2)}rem`,
                              height: `${interpolate(1.5, 1.2)}rem`,
                            }}
                          />
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
                <img
                  src={angleDown}
                  alt="dropdown"
                  style={{
                    marginLeft: `${interpolate(0.25, 0.2)}rem`,
                    height: `${interpolate(1.5, 1.2)}rem`,
                  }}
                  className="hover:scale-110 my-auto"
                  onClick={(e) => parentClicked(e, false)}
                />
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
        <div
          className={`fixed right-4 flex gap-3 items-center self-start z-20 ${
            marqueeVisible ? "top-[46px]" : "top-4"
          }`}
        >
          {!isWalletSecured && (
            <ShieldOff
              onClick={() => showSecureYourWalletModal()}
              size={20}
              color="red"
              className="animate-pulse cursor-pointer"
            />
          )}
          {isConnectIcon ? (
            <img
              onClick={disconnectDapp}
              className="h-[18px] "
              src={storeData?.connect_origin ? worldConnectIcon : worldIcon}
              alt="connect dapp icon"
            />
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Maximize
                    onClick={() => showWalletConnectQrModal(true)}
                    size={20}
                    className="animate-pulse cursor-pointer"
                  />
                </TooltipTrigger>
                <TooltipContent
                  className="bg-secondary-bg text-white border-black mt-[20px]"
                  side="left"
                >
                  <p className="whitespace-nowrap text-sm text-wrap w-[150px]">
                    Scan Wallet Connect QR
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>

        <div className="text-sm w-full h-9 flex justify-center items-center z-10 relative">
          <motion.div
            className="w-fit flex items-center  relative"
            tabIndex={0}
            onHoverStart={() => setShowEditIconWallet(true)}
            onHoverEnd={() =>
              !showEditWalletName && setShowEditIconWallet(false)
            }
            onFocus={() => setShowEditIconWallet(true)}
            onBlur={() => setShowEditIconWallet(false)}
          >
            {showEditWalletName ? (
              <input
                ref={editWalletNameInput}
                type="text"
                placeholder={activeWallet?.walletName}
                className="w-[100px] h-[25px] bg-transparent text-sm h-9 flex justify-center items-center z-10 border-b focus:outline-none focus:border-b-2"
                width="100px"
                height="25px"
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleUpdateName(e, true);

                  if (e.key === "Escape") editNameBox(false);
                }}
              />
            ) : (
              <motion.p className="flex justify-center relative">
                {activeWallet?.walletName}
              </motion.p>
            )}

            <motion.div className="absolute left-[100%] top-1/2 -translate-y-1/2 ">
              <AnimatePresence>
                {(showEditIconWallet || showEditWalletName) && (
                  <motion.div
                    className=""
                    variants={iconVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={{ duration: 0.1 }}
                  >
                    {showEditWalletName ? (
                      <X
                        className="h-5 pl-2 cursor-pointer hover:scale-110"
                        onClick={() => editNameBox(false)}
                      />
                    ) : (
                      <Edit2
                        className="h-3.5 pl-2 cursor-pointer hover:scale-110"
                        onClick={() => editNameBox(true)}
                      />
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        </div>

        <div
          className={`sticky float-start h-16  w-full bg-secondary-bg z-10 rounded-b-xl ${
            marqueeVisible ? "top-[29px]" : "top-0"
          }`}
        ></div>
        <div className="flex justify-center items-center gap-5 mt-[69px] mb-1 w-[70%] pb-3">
          <span className="flex flex-col justify-center items-center gap-2">
            <div
              className={`flex justify-center items-center gap-2 h-10 w-10 rounded-full duration-100 relative ${
                copyClicked ? " scale-90 " : ""
              }`}
              style={{
                backgroundColor: accentColor && `${accentColor}`,
              }}
              onClick={() =>
                copyToClipboard(getAddress(), "Address copied successfully")
              }
              onMouseDown={() => setCopyClicked(true)}
              onMouseUp={() => setCopyClicked(false)}
            >
              <img
                className="h-5 cursor-pointer"
                src={copy}
                alt="copy and paste"
              />
            </div>
            <p className="text-xs">Copy</p>
          </span>
          <span className="flex flex-col justify-center items-center gap-2">
            <img
              src={send}
              alt="Send"
              className={`h-10 cursor-pointer rounded-full p-2 relative`}
              onClick={() => navigate("/dashboard/transaction/add-address")}
              style={{
                backgroundColor: accentColor && `${accentColor}`,
              }}
            />
            <p className="text-xs">Send</p>
          </span>
          <span className="flex flex-col justify-center items-center gap-2">
            <img
              src={send}
              alt="Receive"
              className={`h-10 cursor-pointer rounded-full p-2 rotate-180`}
              style={{
                backgroundColor: accentColor && `${accentColor}`,
              }}
              onClick={() => navigate("/dashboard/receive")}
            />
            <p className="text-xs">Receive</p>
          </span>
          <span className="flex flex-col justify-center items-center gap-2">
            <img
              src={dashboard}
              alt="Dashboard"
              className={`cursor-pointer h-10 p-2 rounded-full relative`}
              style={{
                backgroundColor: accentColor && `${accentColor}`,
              }}
              onClick={() =>
                window.open(`${Config.USER_DASHBOARD_URL}`, "_blank")
              }
            />
            <p className="text-xs">Dashboard</p>
          </span>
        </div>
        {/* TODO: UN COMMENT NOTIFICATION WHEN COMPLETED WITH BE */}
        {/* <Notification
          accentColor={accentColor}
          scrollPosition={scrollPosition}
        /> */}
      </nav>

      {showQrScanner && (
        <Modal
          isOpen={showQrScanner}
          onClose={() => showWalletConnectQrModal(false)}
          children={qrModalChild()}
          headerText={"Scan Wallet connect QR"}
        />
      )}

      {isBackUpWalletModalOpen && (
        <BackUpWallet
          showModal={isBackUpWalletModalOpen}
          toggleModal={showBackUpWalletModal}
        />
      )}
      {isSecureYourWalletModalOpen && (
        <SecureYourWallet
          showModal={isSecureYourWalletModalOpen}
          toggleModal={showSecureYourWalletModal}
        />
      )}
    </>
  );
}
